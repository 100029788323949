
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { RecipeBasket } from '~/types';

interface Data {
  savedResultsToggle: boolean;
  savedResultsPortionsNumber: number;
}

interface Methods {
  toggleText: () => void;
}

interface Computed {
  savedResults: RecipeBasket[];
}

interface Props {
  saveRecipeClickAction: number;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  data() {
    return {
      savedResultsToggle: false,
      savedResultsPortionsNumber: 0,
    };
  },
  props: {
    saveRecipeClickAction: Number,
  },
  computed: {
    ...mapGetters(['savedResults']),
  },
  methods: {
    toggleText() {
      setTimeout(() => {
        this.savedResultsToggle = false;
      }, 3000);
    },
  },
  watch: {
    saveRecipeClickAction: function () {
      this.savedResultsToggle = true;
      this.toggleText();
    },
  },
});
