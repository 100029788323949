
import Vue from 'vue';
import { mapGetters } from 'vuex';
import GoBackBtn from './GoBackBtn.vue';
import FilterBtn from './FilterBtn.vue';
import SearchBar from './SearchBar.vue';
import { Tag } from '~/types';

interface Props {
  showGoBackBtn: boolean;
  showFilterBtn: boolean;
  searchTerm: string | null;
}

interface Methods {
  searchRecipes: (searchVal: string) => void;
}

interface Computed {
  selectedTags: any[];
  searchTags: Tag[];
}

export default Vue.extend<unknown, Methods, Computed, Props>({
  components: {
    GoBackBtn,
    FilterBtn,
    SearchBar,
  },
  props: {
    searchTerm: [String || null],
    showGoBackBtn: {
      type: Boolean,
      default: true,
    },
    showFilterBtn: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters(['selectedTags', 'searchTags']),
  },
  methods: {
    async searchRecipes(searchVal) {
      this.$store.commit('setSearchTerm', searchVal);
      await this.$store.dispatch('getRecipesWithSearch');
    },
  },
});
