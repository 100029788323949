
import debounce from 'lodash/debounce';
import Vue from 'vue';
import { mapGetters } from 'vuex';

interface Props {
  placeholder: string;
  noIcon: boolean;
  noShadow: boolean;
  lightBackground: boolean;
  onSearch: (val: string) => void;
  searchTerm: string | null;
  hasSearchTags: boolean;
}

interface Data {
  searchVal: string | null;
}

interface Methods {
  search: (searchTerm: string) => void;
  submitSearch: (searchTerm: string) => void;
}

interface Computed {
  storeSearchTerm: string | null;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  props: {
    placeholder: String,
    noIcon: {
      type: Boolean,
      default: false,
    },
    hasSearchTags: {
      type: Boolean,
      default: false,
    },
    noShadow: {
      type: Boolean,
      default: false,
    },
    lightBackground: {
      type: Boolean,
      default: false,
    },
    onSearch: {
      type: Function,
    },
    searchTerm: String,
  },
  data() {
    return {
      searchVal: this.searchTerm || '',
    };
  },
  computed: {
    ...mapGetters({ storeSearchTerm: 'searchTerm' }),
  },
  methods: {
    search(searchVal: any) {
      this.onSearch(searchVal);
    },
    submitSearch() {
      (this.$refs.searchInput as HTMLElement).blur();
    },
  },
  watch: {
    storeSearchTerm() {
      this.searchVal = this.storeSearchTerm;
    },
    searchVal(newVal) {
      this.search(newVal);
    },
  },
  created() {
    this.search = debounce(this.search, 500);
  },
});
